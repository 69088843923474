import React from 'react';
import { ResourceCenter } from 'cccisd-laravel-resources';
import style from './style.css';

const OnlineTraining = props => {
    return (
        <React.Fragment>
            <div className={style.header}>
                <div className="container">Online Training</div>
            </div>
            <div className="container">
                <ResourceCenter {...props} />
            </div>
        </React.Fragment>
    );
};

export default OnlineTraining;
