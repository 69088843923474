import React from 'react';
import { RegisterLoginPage } from 'cccisd-laravel-nexus';

const Register = () => {
    const registerLoginProps = {
        registerProps: {
            role: 'learner',
            showPasswordFields: true,
            title: 'Register',
            validateAdditionalFields: values => {
                let errors = {};
                if (!values.first_name) {
                    errors.first_name = 'First name is required.';
                }
                if (!values.last_name) {
                    errors.last_name = 'Last name is required.';
                }
                return errors;
            },
        },
        loginProps: { title: 'Login' },
    };

    return <RegisterLoginPage {...registerLoginProps} />;
};

export default Register;
