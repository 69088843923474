import React from 'react';
import { NavbarContent, NavbarBrand, NavbarLink } from 'cccisd-header';
import DropdownLink from './DropdownLink';
import logo from '../../../../../public/images/header-logo-copy.png';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const Env = window.cccisd.env;
const Fortress = window.cccisd.fortress;
const userRole = Fortress.user.acting.role.handle;

let wpUrl = Env.wpUrl || Appdefs.app.wpUrl;

function WordpressLinks() {
    return (
        <ul className={`nav navbar-nav ${style.wordpressLinks}`}>
            <NavbarLink to={`${wpUrl}/`}>Home</NavbarLink>
            <NavbarLink to="/onlinetraining">Online Training</NavbarLink>
            <NavbarLink to={`${wpUrl}/events`}>Events</NavbarLink>
            <NavbarLink to={`${wpUrl}/faqs`}>FAQs</NavbarLink>
            {userRole === 'uberadmin' && <NavbarLink to="/manage">Manage</NavbarLink>}
            <NavbarContent allowSwitchRoles={false} loginLinkTo="/register" loginLinkLabel="Login/Register" />
        </ul>
    );
}
function SiteAdminWordpressLinks() {
    return (
        <ul className={`nav navbar-nav ${style.wordpressLinks}`}>
            <NavbarLink to={`${wpUrl}/`}>Home</NavbarLink>
            <NavbarLink to="/onlinetraining">Online Training</NavbarLink>
            <NavbarLink to={`${wpUrl}/events`}>Events</NavbarLink>
            <NavbarLink to={`${wpUrl}/faqs`}>FAQs</NavbarLink>
            <DropdownLink to="/quest" label="Admin">
                <NavbarLink to="/quest">Data &amp; Progress</NavbarLink>
                <NavbarLink to="/manage">Manage</NavbarLink>
            </DropdownLink>
            <NavbarContent allowSwitchRoles={false} loginLinkTo="/register" loginLinkLabel="Login/Register" />
        </ul>
    );
}
function Header() {
    return (
        <>
            <header className={style.header}>
                <nav className="navbar navbar-default">
                    <div className="container-fluid">
                        <div className="navbar-header">
                            <button
                                type="button"
                                className="navbar-toggle collapsed"
                                data-toggle="collapse"
                                data-target="#header-navbar-collapse"
                                aria-expanded="false"
                            >
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                            </button>
                            <NavbarBrand
                                logoLinkTo={wpUrl}
                                logo={<img src={logo} className={style.headerLogo} alt="3C Institute Logo" />}
                            />
                        </div>

                        <div className="collapse navbar-collapse navbar-right" id="header-navbar-collapse">
                            {userRole === 'siteAdmin' ? <SiteAdminWordpressLinks /> : <WordpressLinks />}
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
}

export default Header;
