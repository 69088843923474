import React from 'react';
import BHALogo from './BHALogo.svg';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const Boilerplate = window.cccisd.boilerplate;
const Env = window.cccisd.env;

const BHAFooter = () => {
    const year = new Date().getFullYear();

    let wpUrl = Env.wpUrl || Appdefs.app.wpUrl;
    if (!wpUrl.endsWith('/')) {
        wpUrl += '/';
    }

    return (
        <div className={style.footerWrapper}>
            <div className={style.footerInner}>
                <div className={style.topSection}>
                    <div className={style.rightSide}>
                        <img src={BHALogo} alt="BHA Logo" className={style.logo} />
                        <div className={style.text}>
                            <p>Behavioral Health Administration</p>
                            <p>55 Wade Avenue Catonsville, MD 21228</p>
                            <p>(410) 402-8600</p>
                        </div>
                    </div>
                    <div className={style.leftSide}>
                        <ul className={style.links}>
                            <li>
                                <a href={wpUrl}>Home</a>
                            </li>
                            <li>
                                <a href={Boilerplate.url('/onlinetraining')}>Online Training</a>
                            </li>
                            <li>
                                <a href={wpUrl + 'faqs/'}>FAQS</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={style.bottomSection}>
                    <div className={style.bottomInner} id="footerBottomText">
                        <div className={style.topRow}>
                            <div className={style.leftSide}>
                                Website and software produced by{' '}
                                <a href="https://www.3cisd.com/" rel="noopener noreferrer" target="_blank">
                                    3C Institute
                                </a>{' '}
                                &copy; {year} Maryland.gov. All rights reserved.
                            </div>
                            <div className={style.rightSide}>
                                <a
                                    href="https://www.3cisd.com/privacy-policy"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Privacy Policy
                                </a>
                                <a href="https://www.3cisd.com/terms-of-use" rel="noopener noreferrer" target="_blank">
                                    Terms of Use
                                </a>
                                <a href={wpUrl + 'contact/'} rel="noopener noreferrer" target="_blank">
                                    Contact Us
                                </a>
                            </div>
                        </div>
                        <div className={style.bottomRow}>
                            3C Institute services are funded in part by contracts from The National Institute of Mental
                            Health: HSSN 278200443100C, HSSN 271200774100C, HSSN 43MH32060, HSSN 278200444060C.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BHAFooter;
